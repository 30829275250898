import React, {useState, useEffect} from "react";
import { fetchSequence } from "./Utils";

import { Button, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { CodeSandboxOutlined } from '@ant-design/icons';
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import log from 'loglevel';

import { SQHeader, Editor } from "./Components";

function Playground(props) {
  log.info("Playground:" + JSON.stringify(props));

  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("id");
  const sqURL = "/sequence?id=" + key;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    fetchSequence(key).then(function(value) {

      log.info("fetched: " + JSON.stringify(value.data.Template));
      
      setData({template: value.data.Template, 
              rTemplate: value.data.rTemplate,
              required: value.data.vTemplate.dependencies.required,
              secrets: value.data.vTemplate.dependencies.secrets
      });
    })
    .catch(function(error) {
      console.error(error);
    })      
    .finally(function() { 
      setLoading(false);
    });   

    return () => {
      // Clean up the subscription
    };
  }, [key]);


  var extra = <Button type="link" 
                htmlType="submit"                               
                onClick={e => {
                  e.preventDefault();
                  history.push( sqURL );
                }}>
              Go to source sequence
              </Button>  

  return (
      <div>

        <SQHeader subtitle="Sequence"></SQHeader>
        <div style={{ margin: "50px" }}>
          <div className="SQPage">
            <h1><CodeSandboxOutlined style={{marginRight:"20px"}} />Playground</h1>
            <p>Edit the JSON and try running</p>
            <br />
            {
              (loading) 
              ? <Spin />
              : <Editor extra={extra} 
                        id={key}
                        template={data.template}
                        rTemplate={data.rTemplate}
                        required={data.required}
                        secrets={data.secrets}>
                </Editor>
            }
          </div>
        </div>
      </div>
  ); 
}

export default Playground;
