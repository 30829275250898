import log from 'loglevel';


export function isFirstEndpoint(rTemplate, id) {

  //log.info("isFirstEndpoint a " + id);
  id = rTemplate.endpoints[id].id;
  //log.info("isFirstEndpoint b " + id);

  return getIndexFromID(id) === "0";
};

function getIndexFromID(id) {

  return id.split(".")[1];
};


export function isLastEndpointInTemplate(rTemplate, id) {

  //log.info("isLastEndpointInTemplate, id=" + id);

  var index = rTemplate.runningOrder.indexOf(id);
  if (index === rTemplate.runningOrder.length - 1) return true;
  else {
    var tID1 = getTemplateIDFromEndpointID(rTemplate, id);
    var tID2 = getTemplateIDFromEndpointID(rTemplate, rTemplate.runningOrder[index+1]);

    return tID1 !== tID2;
  }
};

export function getTemplateIDFromEndpointID (rTemplate, id) {

  //log.info("getTemplateIDFromEndpointID, ida=" + JSON.stringify(id));

  id = rTemplate.endpoints[id].id;
  //log.info("getTemplateIDFromEndpointID, idb=" + id);

  var parts = id.split(".");
  if (parts.length < 2) throw new Error("Unexpected endpoint ID format " + id);
  return parts[0];
};


export function getParents(rTemplate, id) {

  const parents = [];


  try {
    id = rTemplate.endpoints[id].id;
    var parts = id.split(".");
    if (parts.length < 2) return parents;
  
    var parentID = parts[0];
    parents.push(parentID);
    
    while (rTemplate.endpoints[parentID] !== undefined) {
      parentID = rTemplate.endpoints[parentID].parentID;
      parents.push(parentID);
    }
  }
  catch (err) {
    log.info("TEMPLATE: " + JSON.stringify(rTemplate));
    log.info("ID: " + id);

  }


  return parents;
}