import React from "react";
import { SQHeader } from "./Components";
import { fetchMySecrets, createSecret, deleteSecret, updateSecret, fetchSecret } from "./Utils";
import { useEffect, useState, useCallback } from "react";
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import {Form, Button, Input, Typography, Alert, List, Modal, Row, Col} from "antd";
import { LockOutlined, PlusOutlined, DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import log from 'loglevel';

const { TextArea } = Input;
const { Text, Title } = Typography;


function MySecrets(props) {

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [createError, setCreateError] = useState(null);
  const [existingError, setExistingError] = useState(null);
  const [canApplyEdit, setCanApplyEdit] = useState({ disabled: true });
  const [secretVisible, setSecretVisible] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [secretsLoading, setSecretsLoading] = useState(false);

  log.info("MySequences:" + JSON.stringify(props));

  const delayReload = useCallback(
    (delay) => {
      log.info("D");
      setSecretsLoading(true);
      setTimeout(reload, delay);
    },
    [],
  );

  useEffect(() => {
    delayReload(0);
    return () => {};
  }, [delayReload]);

  function reload() {
    fetchMySecrets()
    .then(function(value) { 
      log.info("MySecrets fetched:" + JSON.stringify(value.data));
      setData(value.data);
    })
    .catch(function(error) {})
    .finally(function(){
      setSecretsLoading(false);
    });
  }

  function createMySecret (values) {
    log.info("Success:", values);
    setCreateLoading(true);
    //Can directly call props here
    createSecret(values.name, values.secret).then(function(value) {
      log.info("create returned " + JSON.stringify(value));
      //setInterval(reload, 1000);   
      delayReload(2000);
    })
    .catch(function(err) {
      console.error(err.response.data.reason);
      setCreateError(err.response.data.reason);
    })
    .finally(function(){
      setCreateLoading(false);
    });
  };

  function deleteMySecret (secret) {

    //Can directly call props here
    log.info("delete: " + JSON.stringify(secret));
    deleteSecret(secret.item.Name).then(function(value) {
      log.info("delete returned " + JSON.stringify(value));
      //setInterval(reload, 1000);  
      delayReload(2000);
    })
    .catch(function(err) {
      console.error(err.response.data.reason);
      setExistingError(err.response.data.reason);
    })
    .finally(function() {
    });
  };

  function editMySecret (secret) {
    setSelected(secret.item.Name);
  }

  function editOk() {

    const newPassword = editForm.getFieldValue("secret");
    updateSecret(selected, newPassword).then(function(value) {
      log.info("updated secret:" + JSON.stringify(value.data));
    })
    .catch(function(err) {
      console.error(err.response.data.reason);
    })
    .finally(function() {
    });  
    //editForm.resetFields();
    setSelected(null);
    hideSecret();
  }

  function editCancel() {
    hideSecret();
    setSelected(null);
  }

  function showSecret() {
    fetchSecret(selected).then(function(value) {
      //log.info("fetched secret:" + JSON.stringify(value.data.secret));
      editForm.setFieldsValue({ "secret": value.data.secret});
      setSecretVisible(true);
      //log.info("fetched secret 2:" + JSON.stringify(editForm.getFieldValue("foo")));

    })
    .catch(function(err) {
      console.error(err.response.data.reason);
    })
    .finally(function() {
    });  
  }

  function hideSecret() {
    editForm.setFieldsValue({ "secret": ""});
    setSecretVisible(false);
  }

  function checkLength(event){
    log.info("checkLength:" + JSON.stringify(event.target.value));
    if (event.target.value.length > 0) setCanApplyEdit({ disabled: false });
    else setCanApplyEdit({ disabled: true });
  }

  function check(changedValues, allValues) {
    log.info("check1:" + JSON.stringify(changedValues));
    log.info("check2:" + JSON.stringify(allValues));
    log.info("check3:" + JSON.stringify(editForm.getFieldValue("secret")));

    //return editForm.getFieldValue("secret").length > 0;
  }

  const codestring = "{'password': '$db_password'}";
  const editTitle = "Edit " + selected;
  return (
    <div>
      <SQHeader subtitle="My Secrets"></SQHeader>

      <div className="SQPage">
      <h1><LockOutlined style={{marginRight:"20px"}} />My Secrets</h1>
        <div>
          <p>Secrets let you store data that you want to reference, but not be visible, within a sequence, such as a password or token. 
            </p><br />
            <p>Secrets are referenced within a sequence using the dollar sign. 
            For example, lets say the password 'foobar' is required to access a database within an API call. Create a secret with some name (e.g. 'db_password') and pass it in the call (via parameter or data field).
            You would write your sequence endpoint along ther following lines:<br/>
            <Text code>"parameters": {codestring}</Text>
            </p><br/>
            <p>Secrets are stored in the AWS Secrets Manager service. Secrets are neither recorded to the SequenceAPI logs, nor any any other persistence mechanism outside of Secrets Manager. 
              They are accessed only at runtime and held in memory for the lifetime of the lambda executing the sequence employing the secret.</p>

          <Form
            layout="inline"
            form={form}
            style={{marginTop:"50px"}}
            onFinish={createMySecret}
            onValuesChange={check}
          >
            <Form.Item label="Name" name="name">
              <Input placeholder="A name for the secret" />
            </Form.Item>
            <Form.Item label="Secret" name="secret">
              <TextArea rows={1} cols={40} autoSize={true} placeholder="The secret you want to reference in a sequence."/>
            </Form.Item>
            <Form.Item >
              <Button type="primary" htmlType="submit" icon={<PlusOutlined />} loading={createLoading}>Create</Button>
            </Form.Item>
            {
              (createError !== null) ?      
              <Alert message={createError} type="error" afterClose={() => setCreateError(null)} closable/> :
              null 
            }
          </Form>
        </div>
        <div style={{marginTop:"50px"}}>
          <Title level={3} style={{marginBottom:"30px"}}>    
            Existing secrets
            {
            (secretsLoading) ? 
            <LoadingOutlined /> :
            null
          }   
          </Title> 
          {
            (existingError !== null) ?      
            <Alert message={existingError} type="error" afterClose={() => setExistingError(null)} closable/> :
            null 
          }
          <Modal  visible={selected !== null} 
                onOk={editOk} 
                onCancel={editCancel} 
                title={editTitle}
                okButtonProps={canApplyEdit}> 
            <Form
                form={editForm}
                initialValues={{
                  residence: ['zhejiang', 'hangzhou', 'xihu']
                }}
                style={{marginBottom:"-20px"}}
              >
                <Form.Item label="Secret" name="secret" shouldUpdate={true}>
                  <TextArea onChange={checkLength} rows={1} cols={40} autoSize={true} placeholder="**************************" />
                </Form.Item>
              </Form>
              <Row>
                <Col offset={3}>
                  <Button icon={<EyeOutlined /> } type="link" onClick={showSecret} disabled={secretVisible} />
                </Col>
                <Col>
                  <Button icon={<EyeInvisibleOutlined /> } type="link" onClick={hideSecret} disabled={!secretVisible}/> 
                </Col>
              </Row>
          </Modal>
          <List bordered dataSource={data} renderItem={item => (
            <List.Item>
                <span>
                  <Title level={5}>{item.Name}</Title>
                  <Button icon={<DeleteOutlined /> } type="link" onClick={() => deleteMySecret({item})} />
                  <Button icon={<EditOutlined />} type="link"  onClick={() => editMySecret({item})} />
                </span>
              </List.Item>
            )}>
          </List>
        </div>
      </div>
    </div>
  ); 
}

export default MySecrets;
