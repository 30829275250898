import React from "react";
import {
  Col,
  Row,
  Button,
  Alert,
  Tag
} from "antd";
import { useHistory } from "react-router-dom";

import { EditOutlined, DeleteOutlined} from '@ant-design/icons';
import { SQHeader, CollectionView } from "./Components";
import {CredentialsContext } from "./CredentialsContext";
import { useEffect, useState, useContext } from "react";
import { fetchCollection, deleteCollection, publishCollection } from "./Utils";

import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import log from 'loglevel';

function Collection(props) {

  log.info("Collection:" + JSON.stringify(props));
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("id");
  const [collection, setCollection] = useState(null);

  useEffect(() => {

    fetchCollection(key).then(function(value) {

      log.info("fetched collection: " + JSON.stringify(value.data));
      setCollection(value.data);
    })
    .catch(function(error) {
      console.error(error);
    });

    return () => {
      // Clean up the subscription
    };
  }, [key]);

  return (
    <div>
      <SQHeader subtitle="Collection"></SQHeader>
      <div className="SQPage">
          <div>
            {
            (collection === null) ? 
            null : 
            <ConfiguredView collection={collection} />
            }
              
          </div>
      </div>
    </div>
  ); 
}

function ConfiguredView (props) {

  const [error, setError] = useState(null);
  const [isPublished, setIsPublished] = useState(props.collection.isPublished);
  const [isPublishing, setIsPublishing] = useState(false);

  const context = useContext(CredentialsContext);
  const isMine = (context === props.collection.author);
  const history = useHistory();

  function deleteThisCollection() {
    //history.push( "/edit?id=" + key );
    deleteCollection(props.collection.id).then(function(value) {
      log.info("delete collection returned " + JSON.stringify(value));
      history.push( "/mycollections" );
    })
    .catch(function(err) {
      console.error(err.response.data);
      setError(err.response.data);
      //setError({visible: true, text: JSON.stringify(err.response.data)});
    })
    .finally(function() {
      //setIsSaving(false);
    });
  }
  
  function publish() {
    setIsPublishing(true);

    publishCollection(props.collection.id).then(function(value) {

      log.info("published collection");
      setIsPublished(true);
    })
    .catch(function(error) {
      console.error(error);
      setError(error.response.data);
    })
    .finally(function() {
      setIsPublishing(false);
    });
  }

  const handleClose = () => {
    setError(null);
  };

  const publishUI = (isMine & isPublished) ?
    <Tag color="#87d068" style={{ marginLeft: "10px" }}>Published</Tag> :
    <Button onClick={publish} style={{ marginLeft: "10px" }} loading={isPublishing}>
      Publish
    </Button>
  ;

  const buttons = (isMine) ? 
    <div style={{ marginTop: "20px", marginBottom: "40px" }} key={"df4t"}>
      <Row>
        <Col>
          <Button onClick={deleteThisCollection}>
            <DeleteOutlined />Delete
          </Button>
          <Button style={{ marginLeft: "10px" }} disabled={true}>
            <EditOutlined /> Edit Name
          </Button>
          <Button style={{ marginLeft: "10px" }} disabled={true}>
            <EditOutlined /> Edit Description
          </Button>
          {publishUI}
        </Col>
      </Row>
      <Row>
        <Col span={15}>   
          {(error !== null) ?      
          <Alert message={error} type="error" afterClose={handleClose} closable/> :
          null }
        </Col> 
      </Row>
    </div>

    :null;

  return (
      <CollectionView  collection={props.collection} extra={buttons}/>
  );
}

export default Collection;
