import React from "react";
import { SQHeader, SequenceCard, LoadingCard } from "./Components";
import { fetchMySequences } from "./Utils";
import { useEffect, useState } from "react";
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import {Col,Row} from "antd";
import { UserOutlined } from '@ant-design/icons';
import log from 'loglevel';

function MySequences(props) {

  const [data, setData] = useState({author: "", loaded: false, sqs: []});

  log.info("MySequences:" + JSON.stringify(props));

  useEffect(() => {

    //log.info("fetch: " + props.id);

    fetchMySequences().then(function(value) {

      log.info("fetched: " + JSON.stringify(value));
      setData({author: value.data.user.name, loaded: true, sqs: value.data.sequences});
    })
    .catch(function(error) {
      console.error(error);
    });

    return () => {
      // Clean up the subscription
    };
  }, []);

  return (
    <div>
      <SQHeader subtitle="Collection"></SQHeader>

      <div className="SQPage">
      <h1><UserOutlined style={{marginRight:"20px"}} />Sequences created by {data.author}</h1>
        <div>
          <Row>

          {
            (data.loaded)
            ?
            data.sqs.map((sq) => (
                
                <Col key={sq.id} >
                  <SequenceCard id={sq.id} 
                                name={sq.Name} 
                                author={data.author} 
                                description={sq.Description} 
                                publishedVersions={sq.PublishedVersions}/>
                </Col>
              ))   
            :
              <div>
                <Col><LoadingCard /></Col>
                <Col><LoadingCard /></Col>
                <Col><LoadingCard /></Col>
              </div>
          }
          </Row>  

        </div>

      </div>
    </div>
  ); 
}

export default MySequences;
