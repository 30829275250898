import React, {useState, useEffect, useContext } from "react";
import { Button, Spin, Typography, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { updateSequence, fetchSequence, cloneSequence, publishSequence } from "./Utils";
import { CodeSandboxOutlined } from '@ant-design/icons';
import { CredentialsContext } from "./CredentialsContext";

import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";

import { SQHeader, Editor } from "./Components";
import log from 'loglevel';

function Edit(props) {
  log.debug("Edit:" + JSON.stringify(props));
  const [data, setData] = useState(null);
  const [newTemplate, setNewTemplate] = useState({template: null, isSaved: true, setAllowPublish: false, isPublished: false});
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("id");
  const mySub = useContext(CredentialsContext);

  const { Text } = Typography;

  useEffect(() => {
    log.info("WILL FETCH: ");

    fetchSequence(key).then(function(value) {

      log.info("fetched: " + JSON.stringify(value.data));
      const publishedVersions = value.data.PublishedVersions;
      const canPublish = !(publishedVersions.includes(value.data.latestVersion));
      const publishedVersion = (publishedVersions.length > 0) ? publishedVersions[publishedVersions.length-1] : -1;
      log.info("canPublish: " + canPublish);

      setData({template: value.data.Template, 
              rTemplate: value.data.rTemplate,
              required: value.data.vTemplate.dependencies.required,
              secrets: value.data.vTemplate.dependencies.secrets,
              author: value.data.Author
            });

      setNewTemplate({template: null, 
                      isSaved: true, 
                      setAllowPublish: canPublish, 
                      publishedVersion: publishedVersion,
                      savedVersion: value.data.latestVersion});
    })
    .catch(function(error) {
      console.error(error);
    })      
    .finally(function() { 
      setLoading(false);
    });       
    
    return () => {
      // Clean up the subscription
    };
  }, [key]);

  function invalidated() {

    log.debug("invalidated");

    setNewTemplate({template: null, 
      isSaved: false, 
      setAllowPublish: false, 
      publishedVersion: newTemplate.publishedVersion,
      savedVersion: newTemplate.savedVersion});                     
  }

  function validated(template) {

    log.debug("validated");

    setNewTemplate({template: template, 
                    isSaved: false, 
                    setAllowPublish: false,
                    publishedVersion: newTemplate.publishedVersion,
                    savedVersion: newTemplate.savedVersion});  
  }

  function publish() {

    setIsPublishing(true);
    publishSequence(key).then(function(value) {
      log.info("PUBLISHED: " + JSON.stringify(value.data));

      setNewTemplate({template: newTemplate.template, 
                      isSaved: newTemplate.isSaved, 
                      setAllowPublish: false,
                      publishedVersion: value.data.version,
                      savedVersion: newTemplate.savedVersion});  
    })
    .catch(function(error) {
      if (error.response !== undefined)
        console.error(error.response);
      else
        console.error(error.message);
    })
    .finally(function() {
      setIsPublishing(false);
    });
  }

  function save() {

    if (newTemplate.template !== null) {
      setIsSaving(true);
      updateSequence(key, newTemplate.template).then(function(value) {
        log.info("SAVE: " + JSON.stringify(value.data));
        //invalidated();
        setNewTemplate({template: null, 
                        isSaved: true, 
                        setAllowPublish: true, 
                        publishedVersion: newTemplate.publishedVersion,
                        savedVersion: value.data.version});  

        //history.push( "/sequence?id=" + key );
      })
      .catch(function(error) {
        if (error.response !== undefined)
          console.error(error.response);
        else
          console.error(error.message);
      })
      .finally(function() {
        setIsSaving(false);
      });
    }
  }

  function clone() {

    setIsSaving(true);

    cloneSequence(key).then(function(value) {
      //log.info("/sequence?id=" + value.data.sequence_id);
      history.push( "/sequence?id=" + value.data.sequence_id );
      //log.info("Required:" + JSON.stringify(value.data.vTemplate.dependencies.required))
    })
    .catch(function(error) {
      console.error(error.response);
    })
    .finally(function() {       
      setIsSaving(false);
    });
  }

  var extra;
  
  if (!loading) {

    if (mySub === data.author ) { 
      extra = 
      <span key="gggg">
        <Button onClick={save} disabled={newTemplate.template === null} loading={isSaving}>
            Save
        </Button>  
        {
          (newTemplate.isSaved) ? null :
          <Text type="secondary" style={{ marginLeft: "10px" }}>Unsaved changes</Text> 
        }
        <Button style={{ marginLeft: "10px" }} onClick={publish} disabled={!newTemplate.setAllowPublish} loading={isPublishing}>
            Publish v{newTemplate.savedVersion}
        </Button>      
        {
          (newTemplate.publishedVersion > -1) ?     
            <Tag color="#87d068" style={{ marginLeft: "10px" }}>Published v{newTemplate.publishedVersion}</Tag> 
          : null
        } 
      </span>
    }
    else {
      extra = 
        <Button onClick={clone} disabled={newTemplate.template === null} loading={isSaving}>
          Clone
        </Button>  
    }     
  }

  return (
      <div>

        <SQHeader subtitle="Sequence"></SQHeader>
        <div style={{ margin: "50px" }}>
          <div className="SQPage">
            <h1><CodeSandboxOutlined style={{marginRight:"20px"}} />Edit</h1>
            <p>Edit the JSON, check and run the Sequence and then save it if you are happy.</p>
            <br />
            {
              (loading) 
              ? <Spin />
              : <Editor extra={extra} 
                        id={key}
                        onValidation={validated} 
                        onInvalidation={invalidated} 
                        template={data.template}
                        rTemplate={data.rTemplate}
                        required={data.required}
                        secrets={data.secrets}
                        >
                </Editor>
            }
            
          </div>
        </div>
      </div>
  ); 
}
export default Edit;
