import React, {useState, useEffect} from "react";
import { Button, Alert, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { createSequence, checkSequence } from "./Utils";
import { PlusOutlined } from '@ant-design/icons';
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";

import { SQHeader, Editor } from "./Components";
import log from 'loglevel';

const basicTemplate ={
  name: "My Sequence",
  description: "A sequence that calls some API.",
  dictionary:{
    url: "https://mydefaultserver.com/"
  },
  outputs: [],
  endpoints: [{
      name: "The first API call",
      resource: "someaction",
      url: "https://overridedefaultserver.com/",
      headers: {
        'x-api-key': 'Some Random Key'
      } , 
      parameters: {
      },
      data: {
        json: {

        }
      },
      goals: {
        status: 200,
        output: {
        }
      }
    }
  ] 
}


function NewSequence(props) {
  log.info("Edit:" + JSON.stringify(props));
  const [error, setError] = useState({visible: false, text:""});
  const [data, setData] = useState(null);
  const [newTemplate, setNewTemplate] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();


  useEffect(() => {

    checkSequence({template: basicTemplate})
    .then(function(value) {
      log.info("Got load response XXX:" + JSON.stringify(value.data));

      setData({template: basicTemplate, 
              rTemplate: value.data.template,
              required: value.data.required,
              secrets: value.data.secrets
            });
      //log.info("doCheck:" + JSON.stringify(data));  
      //if (props.onValidation !== undefined) props.onValidation(data.template);
    })
    .catch(function(err) {

      log.info("ERROR:" + JSON.stringify(err));
      setError({visible: true, text: JSON.stringify(err.response.data)});
      //if (props.onInvalidation !== undefined) props.onInvalidation();
    })
    .finally(function() { 
      setLoading(false);
    });       
    
    return () => {
      // Clean up the subscription
    };
  }, []);

  function invalidated() {

    setNewTemplate(null);
  }

  function validated(template) {

    setNewTemplate(template);
  }
  const handleClose = () => {
    setError({visible: false, text: ""});
  };

  function save() {

    if (newTemplate !== null) {
      setIsSaving(true);
      createSequence(newTemplate).then(function(value) {
        log.info("create returned " + JSON.stringify(value));
        invalidated();
        history.push( "/sequence?id=" + value.data.id );
      })
      .catch(function(err) {
        console.error(err);
        setError({visible: true, text: JSON.stringify(err.response.data)});
      })
      .finally(function() {
        setIsSaving(false);
      });
    }

  }

  var extra = <Button onClick={save} disabled={newTemplate === null} loading={isSaving}>
                Save
              </Button>  

  return (
      <div>

        <SQHeader subtitle="Sequence"></SQHeader>
        <div style={{ margin: "50px" }}>
          <div className="SQPage">
            <h1><PlusOutlined style={{marginRight:"20px"}} />New</h1>

            {/*<SQEditor></SQEditor>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />*/}
            <p>Edit the placeholder JSON, check and run the Sequence and then save it if you are happy.</p>
            <br />
            {
              (error.visible) 
              ? <Alert message={error.text} type="error" closable afterClose={handleClose} />
              : null
            }
            {
              (loading) 
              ? <Spin />
              : <Editor extra={extra} 
                        onValidation={validated} 
                        onInvalidation={invalidated} 
                        template={data.template}
                        rTemplate={data.rTemplate}
                        required={data.required}
                        secrets={data.secrets}>
                </Editor>
            }
            
          </div>
        </div>
      </div>
  ); 
}
export default NewSequence;
