import axios from "axios";
import AWS from 'aws-sdk/global'
import { Auth } from "@aws-amplify/auth";
import { Cache } from 'aws-amplify';
import log from 'loglevel';

fetchMyCollections();
export const url = "https://api." + process.env.REACT_APP_SUBDOMAIN + ".sequenceapi.com/v1/users/";

export function runFromID(id, inputs, context ) {
  //log.info("MY IDENTITY: " + AWS.config.credentials._identityId);

  if (context !== undefined) 
    return callWithAuthorization("runSequenceAuthenticated", {sequence_id: id}, inputs);
  return callAnonymous("runSequenceUnauthenticated", {sequence_id: id, requester: AWS.config.credentials._identityId}, inputs);
}

export function runFromTemplate(rTemplate, name, inputs, required, secrets, context) {

  log.info("secrets : " + JSON.stringify(secrets));


  const data = {inputs: inputs, template: rTemplate, required: required, secrets: secrets, name: name};
  log.info("MY template IDENTITY : " + AWS.config.credentials._identityId);

  if (context !== undefined) 
    //return call("runSequenceFromTemplate", {requester: AWS.config.credentials._identityId}, data);
    return callWithAuthorization("runSequenceFromTemplateAuthenticated", {}, data);
  return callAnonymous("runSequenceFromTemplateUnauthenticated", {requester: AWS.config.credentials._identityId}, data);
}

export function createSequence(template) {

  const data = {template: template};

  return callWithAuthorization("createSequence", {}, data);
}

export function updateSequence(id, template) {

  const data = {template: template};

  return callWithAuthorization("updateSequence", {sequence_id: id}, data);
}

export function publishSequence(id) {

  return callWithAuthorization("publishSequence", {sequence_id: id});
}

export function deleteSequence(id) {

  return callWithAuthorization("deleteSequence", {sequence_id: id});
}

export function fetchSequence(id) {

  /*
  if (context !== undefined) 
    return callWithAuthorization("fetchSequenceAuthenticated", {sequence_id: id});
  return callAnonymous("fetchSequenceUnauthenticated", {sequence_id: id});
  */
  return callPrioritiseAuthenticated("fetchSequence", {sequence_id: id});

}

export function fetchSequenceVersion(id, version) {

  return callPrioritiseAuthenticated("fetchSequence", {sequence_id: id, template_version: version});
}

export function fetchMySequences() {

  return callWithAuthorization("fetchMySequences", {});
}

export function fetchMySecrets() {

  return callWithAuthorization("fetchMySecrets", {});
}

export function createSecret(name, value) {

  return callWithAuthorization("createSecret", {}, {name: name, value: value});
}

export function deleteSecret(name) {

  return callWithAuthorization("deleteSecret", {}, {name: name});
}

export function fetchSecret(name) {

  return callWithAuthorization("fetchSecret", {}, {name: name});
}

export function updateSecret(name, value) {

  return callWithAuthorization("updateSecret", {}, {name: name, value: value});
}

export function fetchMyRuns(olderThan) {

  if (olderThan !== undefined) return callWithAuthorization("fetchMyRuns", {date: olderThan});

  return callWithAuthorization("fetchMyRuns", {});
}

export function fetchRunHistory(rid) {

  return callWithAuthorization("fetchRunHistory", {run_id: rid});
}

export function fetchSequenceSummary(id) {

  /*
  if (context !== undefined) 
    return callWithAuthorization("fetchSequenceSummaryAuthenticated", {sequence_id: id});
  return callAnonymous("fetchSequenceSummaryUnauthenticated", {sequence_id: id});
*/
  return callPrioritiseAuthenticated("fetchSequenceSummary", {sequence_id: id});
}

export function cloneSequence(id) {

  return callWithAuthorization("cloneSequence", {sequence_id: id});
}

export function fetchCollection(id) {

  /*
  if (context !== undefined) 
    return callWithAuthorization("fetchCollectionAuthenticated", {collection_id: id});
  return callAnonymous("fetchCollectionUnauthenticated", {collection_id: id});
  */
 return callPrioritiseAuthenticated("fetchCollection", {collection_id: id});
}

export function cloneCollection(id) {

  return callWithAuthorization("cloneCollection", {collection_id: id});
}

export function publishCollection(id) {

  return callWithAuthorization("publishCollection", {collection_id: id});
}

export function createCollection(name, description) {

  return callWithAuthorization("createCollection", {}, {name: name, description: description});
}

export function addSequenceToCollection(collection_id, sequence_id) {

  return callWithAuthorization("addToCollection", {parent_id: collection_id, child_id: sequence_id, type: "SQ"});
}

export function deleteFromCollection(collection_id, sequence_id) {

  return callWithAuthorization("deleteFromCollection", {parent_id: collection_id, child_id: sequence_id});
}

export function deleteCollection(id) {

  return callWithAuthorization("deleteCollection", {collection_id: id});
}
/*
export function lazilyFetchMyCollections() {

  fetchMySequences().then(function(value) {

    log.info("fetched XXX: " + JSON.stringify(value));
    //Cache.setItem("Collections", newRun); 
    //setData({author: value.data.user.name, loaded: true, sqs: value.data.sequences});
  })
  .catch(function(error) {
    console.error(error);
  });

  return callWithAuthorization("fetchMyCollections", {});
}
*/
export function fetchMyCollections() {

  const fetch = callWithAuthorization("fetchMyCollections", {});

  fetch.then(function(value) {

    log.info("fetched collections: " + JSON.stringify(value.data));
    Cache.setItem("Collections", value.data); 
    //setData({author: value.data.user.name, loaded: true, sqs: value.data.sequences});
  })
  .catch(function(error) {
    console.error(error);
  });

  return fetch
}

export function checkSequence(vTemplate) {

  return callAnonymous("checkSequence", {}, vTemplate);
}

function convertMapToURLParams(params) {
  if (params === undefined) return "";

  if  (Object.keys(params).length === 0) return "";

  return (
    "?" +
    Object.keys(params)
      .map(key => key + "=" + params[key])
      .join("&")
  );
}

function callAnonymous(resource, params, data) {

  const options = {
    method: "POST",
    mode: "cors",
    headers: {"Content-Type": "application/json"},
    url: url + resource + convertMapToURLParams(params)
  };

  if (data !== null) options.data = data;
  log.info("callAnonymous:" + JSON.stringify(options));

  return axios(options);
}

async function callWithAuthorization(resource, params, data) {
  log.info("callWithAuthorization 1");

  return await Auth.currentAuthenticatedUser()
  .then(user => {
    var token = user["signInUserSession"]["idToken"]["jwtToken"];
    //log.info("User: ", JSON.stringify(user));
    //log.info(token);

    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      url: url + resource + convertMapToURLParams(params)
    };

    if (data !== null) options.data = data;
    log.info("callWithAuthorization 2:" + JSON.stringify(options));
  

    return axios(options);
  })
  .catch(function(error) {
    throw error;
  });
}

async function callPrioritiseAuthenticated(resource, params, data) {

  const options = {
    method: "POST",
    mode: "cors"
  };

  if (data !== null) options.data = data;

  return await Auth.currentAuthenticatedUser()
  .then(user => {
    const token = user["signInUserSession"]["idToken"]["jwtToken"];

    options.headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    }
    options.url = url + resource + "Authenticated" + convertMapToURLParams(params);
    log.info("callPrioritiseAuthenticated 1:" + JSON.stringify(options));
    return axios(options);
  })
  .catch(function(error) {

    options.url = url + resource + "Unauthenticated" + convertMapToURLParams(params);
    log.info("callPrioritiseAuthenticated 2:" + JSON.stringify(options));
    return axios(options);
  });
}

export function createUID() {
  return Math.random()
  .toString(20)
  .substr(2, 6);
};
